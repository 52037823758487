import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Directive,
} from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import * as jQuery from 'jquery';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
import { TranslateService } from '@ngx-translate/core';
const ALOWED_LANGUAGES = ['en', 'es'];
declare var $: any;
@Component({
  selector: 'app-navbar-cmp',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public userData: any = {};
  public pageTitle: string;

  langIcon = 'US.svg';
  language = 'English';
  lang: any;
  languages = [
    {
      value: 'en',
      title: 'English',
      image: 'assets/img/flags/US.svg',
    },
    {
      value: 'es',
      title: 'Spanish',
      image: 'assets/img/flags/ES.svg',
    },
  ];
  selectedLan = this.languages[0];
  @ViewChild('app-navbar-cmp', { static: false }) button: any;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private appSettingsService: AppSettingsService,
    public translate: TranslateService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }
  switchLang(Lang: string) {
    this.translate.use(Lang);
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');
    } else {
      setTimeout(function () {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    this.appSettingsService.userSettingsSource.subscribe((resp) => {
      this.userData = resp;
      this.setUpLanguge();
    });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    console.log('listTitles', this.listTitles);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();

        const $layer = document.getElementsByClassName('close-layer')[0];
        const $toolTip = jQuery('mat-tooltip-component');
        if ($toolTip) {
          $toolTip.remove();
        }

        if ($layer) {
          $layer.remove();
        }
      });
  }
  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function () {
      // asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    // $('html').removeClass('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (let i = 0; i < this.listTitles.length; i++) {
      if (
        this.listTitles[i].type === 'link' &&
        this.listTitles[i].path === titlee
      ) {
        return this.userData?.role_id === 3 &&
          this.translate.currentLang === 'es'
          ? this.listTitles[i].title_so
          : this.listTitles[i].title;
      } else if (this.listTitles[i].type === 'sub') {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          const subtitle =
            this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return this.pageTitle;
  }

  setUpLanguge() {
    const storedLang =
      this.userData?.role_id === 1
        ? 'en'
        : localStorage.getItem('lang') ||
          window.navigator.language ||
          this.translate.getBrowserLang();
    this.changeLanguage(
      storedLang && ALOWED_LANGUAGES.includes(storedLang) ? storedLang : 'en'
    );
  }
  // LaunguageButton
  async changeLanguage(lang: string) {
    if (lang && ALOWED_LANGUAGES.includes(lang)) {
      this.selectedLan = this.languages.find((e) => e.value === lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
    }
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }
  backClicked() {
    this.location.back();
  }

  logOut() {
    this.appSettingsService.sessionExpire().then((res) => {
      // this.router.navigate(["/login"]);
      const basePath = window.location.origin;
      console.log('checking url', basePath, window.location)
      // location.reload();
      if (this.userData?.role === 'distributor') {
        location.href = basePath + '/partner';
      } else if (this.userData?.role_id === 1) {
        location.href = basePath + '/admin';
      } else {
        location.href = basePath + '/login';
      }
    });
  }
}
