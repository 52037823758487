import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { menuRoutes } from '../../../core/menu/menu';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  title_so?: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  icontype?: string;
  type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/user/profile',
    title: 'My Profile',
    title_so: 'Mi perfil',
    type: 'link',
    icontype: 'person',
  },
  {
    path: '/civilian',
    title: 'Civilian',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/lawyers',
    title: 'Partners',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/request',
    title: 'Requests',

    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/user/request',
    title: 'Requests',
    title_so: 'Pedido',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/recordings',
    title: 'Recordings',
    type: 'link',
    icontype: 'videocam',
  },
  {
    path: '/articles',
    title: 'Articles',
    type: 'link',
    icontype: 'article',
  },
  {
    path: '/safety-tips',
    title: 'Safety Tips',
    type: 'link',
    icontype: 'tips_and_updates',
  },
  {
    path: '/broadcast',
    title: 'Broadcast',
    type: 'link',
    icontype: 'message',
  },
  {
    path: '/banners',
    title: 'Banners',
    type: 'link',
    icontype: 'ad_units',
  },
  {
    path: '/user/recordings',
    title: 'Recordings',
    title_so: 'Grabaciones',
    type: 'link',
    icontype: 'videocam',
  },
  {
    path: '/user/civilian-plans',
    title: 'Service Plans',
    type: 'link',
    title_so: 'Planes de servicio',
    icontype: 'manage_accounts',
  },
  {
    path: '/videos',
    title: 'Videos',
    type: 'link',
    icontype: 'video_library',
  },
  {
    path: '/tutorials',
    title: 'Tutorials',
    type: 'link',
    icontype: 'app_settings_alt',
  },
  {
    path: '/user/agreement',
    title: 'User Agreement',
    type: 'link',
    icontype: 'gavel',
  },
  {
    path: '/subscriptions',
    title: 'Subscriptions',
    type: 'sub',
    icontype: 'subscriptions',
    collapse: 'subscriptions',
    children: [
      { path: 'invoice', title: 'Invoice', ab: 'I' },
      { path: 'reports', title: 'Reports', ab: 'R' },
    ],
  },
  {
    path: '/manage-promo',
    title: 'Manage Promo Code',
    type: 'sub',
    icontype: 'subscriptions',
    collapse: 'manage-promo',
    children: [
      { path: 'organization', title: 'Partners', ab: 'O' },
      { path: 'influencers', title: 'Influencers', ab: 'I' },
      { path: 'promo-codes', title: 'Promocodes', ab: 'P' },
      { path: 'referrals', title: 'Referrals', ab: 'R' },
    ],
  },
  {
    path: '/service-plans',
    title: 'Serivce Plans',
    type: 'link',
    icontype: 'card_membership',
  },
  {
    path: '/support',
    title: 'Support',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/components',
    title: 'Components',
    type: 'sub',
    icontype: 'apps',
    collapse: 'components',
    children: [
      { path: 'buttons', title: 'Buttons', ab: 'B' },
      { path: 'grid', title: 'Grid System', ab: 'GS' },
      { path: 'panels', title: 'Panels', ab: 'P' },
      { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
      { path: 'notifications', title: 'Notifications', ab: 'N' },
      { path: 'icons', title: 'Icons', ab: 'I' },
      { path: 'typography', title: 'Typography', ab: 'T' },
    ],
  },
  {
    path: '/forms',
    title: 'Forms',
    type: 'sub',
    icontype: 'content_paste',
    collapse: 'forms',
    children: [
      { path: 'regular', title: 'Regular Forms', ab: 'RF' },
      { path: 'extended', title: 'Extended Forms', ab: 'EF' },
      { path: 'validation', title: 'Validation Forms', ab: 'VF' },
      { path: 'wizard', title: 'Wizard', ab: 'W' },
    ],
  },
  {
    path: '/tables',
    title: 'Tables',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'tables',
    children: [
      { path: 'regular', title: 'Regular Tables', ab: 'RT' },
      { path: 'extended', title: 'Extended Tables', ab: 'ET' },
      { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' },
    ],
  },
  {
    path: '/maps',
    title: 'Maps',
    type: 'sub',
    icontype: 'place',
    collapse: 'maps',
    children: [
      { path: 'google', title: 'Google Maps', ab: 'GM' },
      { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
      { path: 'vector', title: 'Vector Map', ab: 'VM' },
    ],
  },
  {
    path: '/widgets',
    title: 'Widgets',
    type: 'link',
    icontype: 'widgets',
  },
  {
    path: '/charts',
    title: 'Charts',
    type: 'link',
    icontype: 'timeline',
  },
  {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'date_range',
  },
  {
    path: '/auth',
    title: 'Pages',
    type: 'sub',
    icontype: 'image',
    collapse: 'auth',
    children: [
      { path: 'login', title: 'Login Page', ab: 'LP' },
      { path: 'forgot-password', title: 'Forgot Password', ab: 'FP' },
    ],
  },
  {
    path: '/cms',
    title: 'CMS',
    type: 'link',
    icontype: 'pages',
  },
  {
    path: '/configuration',
    title: 'Configuration',
    type: 'link',
    icontype: 'configuration',
  },
  // {
  //     path: '/call-centre',
  //     title: 'Call Centres',
  //     type: 'link',
  //     icontype: 'support_agent'
  // },
  // {
  //     path: '/call-centre/archived',
  //     title: 'Archived Call Centres',
  //     type: 'link',
  //     icontype: 'support_agent'
  // },
  {
    path: '/call-centre',
    title: 'Call Centers',
    type: 'sub',
    icontype: 'support_agent',
    collapse: 'call-centre',
    children: [
      { path: 'call-centres', title: 'Active List', ab: 'AL' },
      { path: 'archived', title: 'Inactive List', ab: 'AL' },
    ],
  },
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  lang: any;
  ps: any;
  public userData: any = {};
  constructor(
    private appSettingsService: AppSettingsService,
    private router: Router,
    public translate: TranslateService
  ) {}
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  // switchLang(Lang: string) {
  //   this.translate.use(Lang);
  // }

  ngOnInit() {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar .sidebar-wrapper')
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    this.appSettingsService.userSettingsSource.subscribe((resp) => {
      this.userData = resp;
      this.menuItems = this.appSettingsService.getMenuItemsByRole();
    });
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  logOut() {
    localStorage.removeItem('userData');
    this.appSettingsService.sessionExpire().then((res) => {
      // this.router.navigate(["/login"]);
      const basePath = location.href.split('#');
      location.href = basePath[0] + '/login';
      location.reload();
    });
  }
}
