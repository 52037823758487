import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { StaticQrCodeComponent } from './modules/shared/static-qrCode/static-qrCode.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    data: {
      type: 'AuthComponent',
    },
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  // Admin Routes
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_ADMIN',
    },
    runGuardsAndResolvers: 'always',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'civilian',
        loadChildren: () =>
          import('./modules/civilian/civilian.module').then(
            (m) => m.CivilianModule
          ),
        data: { breadcrumb: 'Civilian' },
      },
      {
        path: 'lawyers',
        loadChildren: () =>
          import('./modules/lawyers/lawyers.module').then(
            (m) => m.LawyersModule
          ),
        data: { breadcrumb: 'Lawyers' },
      },
      {
        path: 'request',
        loadChildren: () =>
          import('./modules/request/request.module').then(
            (m) => m.RequestModule
          ),
        data: { breadcrumb: 'Requests' },
      },
      {
        path: 'static-qrcode',
        component: StaticQrCodeComponent,
        // pathMatch: 'full',
      },
      {
        path: 'recordings',
        loadChildren: () =>
          import('./modules/recordings/recordings.module').then(
            (m) => m.RecordingsModule
          ),
        data: { breadcrumb: 'Recordings' },
      },
      {
        path: 'service-plans',
        loadChildren: () =>
          import('./modules/service-plans/service-plans.module').then(
            (m) => m.ServicePlansModule
          ),
        data: { breadcrumb: 'Service Plans' },
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
          ),
        data: { breadcrumb: 'Subcriptions' },
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./modules/support/support.module').then(
            (m) => m.SupportModule
          ),
        data: { breadcrumb: 'Support' },
      },
      {
        path: 'cms',
        loadChildren: () =>
          import('./modules/cms/cms.module').then((m) => m.CmsModule),
        data: { breadcrumb: 'CMS' },
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./modules/configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          ),
        data: { breadcrumb: 'Configuration' },
      },

      {
        path: 'tutorials',
        loadChildren: () =>
          import('./modules/tutorials/tutorial.module').then(
            (m) => m.TutorialsModule
          ),
        data: { breadcrumb: 'Videos' },
      },

      {
        path: 'broadcast',
        loadChildren: () =>
          import('./modules/broadcast/broadcast.module').then(
            (m) => m.BroadcastsModule
          ),
        data: { breadcrumb: 'Broadcast' },
      },

      {
        path: 'call-centre',
        loadChildren: () =>
          import('./modules/call-centre/call-centre.module').then(
            (m) => m.CallCentreModule
          ),
        data: { breadcrumb: 'Call Centers' },
      },
      {
        path: 'manage-promo',
        children: [
          {
            path: 'organization',
            loadChildren: () =>
              import('./modules/organization/organization.module').then(
                (m) => m.OrganizationModule
              ),
            data: { breadcrumb: 'Organization' },
          },
          {
            path: 'influencers',
            loadChildren: () =>
              import('./modules/influencer/influencer.module').then(
                (m) => m.InfluencerModule
              ),
            data: { breadcrumb: 'Organization' },
          },
          {
            path: 'promo-codes',
            loadChildren: () =>
              import('./modules/promo-code/promo-code.module').then(
                (m) => m.PromocodeModule
              ),
            data: { breadcrumb: 'Organization' },
          },
          {
            path: 'referrals',
            loadChildren: () =>
              import('./modules/promo-code/promo-code.module').then(
                (m) => m.PromocodeModule
              ),
            data: { breadcrumb: 'Organization' },
          },
        ],
      },
      {
        path: 'manage_content',
        children: [
          {
            path: 'zipcode',
            loadChildren: () =>
              import('./modules/zipcode/zipcode.module').then(
                (m) => m.ZipcodeModule
              ),
            data: { breadcrumb: 'Zipcode' },
          },
          {
            path: 'dashboard',
            loadChildren: () =>
              import(
                './modules/zipcode/content-dashboard/content-dashboard.module'
              ).then((m) => m.ContentDashboardModule),
            data: { breadcrumb: 'Dashboard' },
          },
          {
            path: 'banners',
            loadChildren: () =>
              import('./modules/banners/banners.module').then(
                (m) => m.BannersModule
              ),
            data: { breadcrumb: 'Broadcast' },
          },
          {
            path: 'videos',
            loadChildren: () =>
              import('./modules/videos/videos.module').then(
                (m) => m.VideosModule
              ),
            data: { breadcrumb: 'Videos' },
          },
          {
            path: 'articles',
            loadChildren: () =>
              import('./modules/article/article.module').then(
                (m) => m.ArticleModule
              ),
            data: { breadcrumb: 'Article' },
          },
          {
            path: 'safety-tips',
            loadChildren: () =>
              import('./modules/safety-tips/safety-tips.module').then(
                (m) => m.SafetyTipsModule
              ),
            data: { breadcrumb: 'Safety Tips' },
          },
          {
            path: 'emer_contact',
            loadChildren: () =>
              import('./modules/emergency_contact/emer-contact.module').then(
                (m) => m.EmerContactModule
              ),
            data: { breadcrumb: 'EmerContact' },
          },
        ],
      },
    ],
  },
  // Civillian Routes
  {
    path: 'user',
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_USER',
    },
    runGuardsAndResolvers: 'always',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'request',
        loadChildren: () =>
          import('./modules/request/request.module').then(
            (m) => m.RequestModule
          ),
        data: { breadcrumb: 'Requests' },
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('./modules/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
        data: { breadcrumb: 'Requests' },
      },
      {
        path: 'recordings',
        loadChildren: () =>
          import('./modules/recordings/recordings.module').then(
            (m) => m.RecordingsModule
          ),
        data: { breadcrumb: 'Recordings' },
      },
      {
        path: 'civilian-plans',
        loadChildren: () =>
          import('./modules/civilian-plan/plans.module').then(
            (m) => m.PlansModule
          ),
        data: { breadcrumb: 'Plans' },
      },
      {
        path: 'agreement',
        loadChildren: () =>
          import('./modules/agreement/agreement.module').then(
            (m) => m.AgreementModule
          ),
        data: { breadcrumb: 'Agreement' },
      },
    ],
  },
  // Partner Routes
  {
    path: 'partner',
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_DISTRIBUTOR',
    },
    runGuardsAndResolvers: 'always',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/partner-profile/partner-profile.module').then(
            (m) => m.PartnerProfileModule
          ),
      },
      {
        path: 'promo-codes',
        loadChildren: () =>
          import('./modules/promo-code/promo-code.module').then(
            (m) => m.PromocodeModule
          ),
        data: { breadcrumb: 'Organization' },
      },
      {
        path: 'banners',
        loadChildren: () =>
          import('./modules/banners/banners.module').then(
            (m) => m.BannersModule
          ),
        data: { breadcrumb: 'Broadcast' },
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('./modules/videos/videos.module').then((m) => m.VideosModule),
        data: { breadcrumb: 'Videos' },
      },
      {
        path: 'articles',
        loadChildren: () =>
          import('./modules/article/article.module').then(
            (m) => m.ArticleModule
          ),
        data: { breadcrumb: 'Article' },
      },
      {
        path: 'safety-tips',
        loadChildren: () =>
          import('./modules/safety-tips/safety-tips.module').then(
            (m) => m.SafetyTipsModule
          ),
        data: { breadcrumb: 'Safety Tips' },
      },
    ],
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];
