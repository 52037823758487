import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {
  AppSettingsService,
  UserSettingsInterface,
} from '../core/services/app-settings.service';
import { mergeMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorage: LocalStorage,
    private appSettingsService: AppSettingsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      this.localStorage
        .getItem(this.appSettingsService.tokenName)
        .pipe(
          mergeMap((token: string) => {
            if (token) {
              // verify user by token
              if (this.appSettingsService.userSettingsSource.value) {
                return this.appSettingsService.userSettingsSource;
              }
              return this.appSettingsService.getUserByToken();
            }
            return of(null);
          }),
          map((userData: UserSettingsInterface) => {
            if (userData) {
              // check for role admin
              if (userData.role_id !== 1 && next.data.role === 'ROLE_ADMIN') {
                if (userData.role === 'distributor') {
                  this.router.navigate(['/partner/profile'], {
                    queryParams: {},
                  });
                } else {
                  this.router.navigate(['/user/profile'], { queryParams: {} });
                }
                return resolve(false);
              }
              if (
                userData.role === 'distributor' &&
                next.data.role === 'ROLE_USER'
              ) {
                this.router.navigate(['/partner/profile'], {
                  queryParams: {},
                });
                return resolve(false);
              }
              if (next.data.type === 'AuthComponent') {
                if (userData.role_id === 1) {
                  this.router.navigate(['/dashboard']);
                } else if (userData.role === 'distributor') {
                  this.router.navigate(['/partner/profile'], {
                    queryParams: {},
                  });
                } else {
                  this.router.navigate(['/user/profile'], {
                    queryParams: next.queryParams,
                  });
                }
                return resolve(false);
              }
              return resolve(true);
            } else {
              if (next.data.type === 'AuthComponent') {
                return resolve(true);
              }
              this.router.navigate(['/login'], { queryParams: {} });
              return resolve(false);
            }
          })
        )
        .subscribe();
    });
  }
}
