import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fadeInUp400ms } from 'src/app/animations/fade-in-up.animation';
import { fadeInRight400ms } from 'src/app/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/app/animations/scale-in.animation';
import { stagger40ms } from 'src/app/animations/stagger.animation';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from 'ngx-qrcode2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-static-qrcode',
  templateUrl: './static-qrCode.component.html',
  styleUrls: ['./static-qrCode.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms, stagger40ms],
})
export class StaticQrCodeComponent implements OnInit {
  @ViewChild('invoice') invoice: ElementRef;
  generatedLink = environment.wesiteURL;

  elementType = NgxQrcodeElementTypes.IMG;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  constructor() {}

  ngOnInit(): void {}

  public downloadPDF(): void {
    // let invoice = document.getElementById("invoice");
    const invoice = this.invoice.nativeElement;
    const options = {
      margin: 0,
      filename: 'QR_Code.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a5', orientation: 'portrait' },
    };
    html2pdf()
      .set(options)
      .from(invoice)
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        window.open(pdf.output('bloburl'), '_blank');
      });

    // html2pdf().set(options).from(invoice).save();
  }

  downloadImage() {
    const invoice = this.invoice.nativeElement;
    html2canvas(invoice).then(function (canvas) {
      // Convert the canvas to blob
      canvas.toBlob(function (blob) {
        // To download directly on browser default 'downloads' location
        const link = document.createElement('a');
        link.download = `QR-Code.png`;
        link.href = URL.createObjectURL(blob);
        link.click();

        // To save manually somewhere in file explorer
        this.window.saveAs(blob, 'image.png');
      }, 'image/png');
    });
  }
}
