import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { forkJoin, BehaviorSubject, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HttpCancelService } from './httpcancel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../modules/shared/confirm-dialog/confirm-dialog.component';
import { ExportToCsv } from 'export-to-csv';

import { menuRoutes } from '../../core/menu/menu';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  // source for observable
  public userSettingsSource: BehaviorSubject<any> = new BehaviorSubject(null);
  // observable stream
  public userSettings$ = this.userSettingsSource.asObservable();
  private showAlert: boolean | string;
  public tokenName = 'ng-token';
  public prioritiesList = [];
  public specialitiesList = [];
  public statusList = [];
  public couponsRedeemed = [];
  alertClosed: boolean;
  public passwordWarningMessage =
    'Password should have 8 characters and must include at least one uppercase, one lowercase and a numeric number 0-9.';
  // and a special character ~ ` ! @ # $ % ^ & * ( ) - _ = + { }  : ; ' < > , . / ?
  public passwordWariningMessageSpanish =
    'La contraseña debe tener 8 caracteres y debe incluir al menos una mayúscula, una minúscula y un número numérico del 0 al 9.';
  public passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;

  constructor(
    private http: HttpClient,
    protected localStorage: LocalStorage,
    private httpCancelService: HttpCancelService,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog // private toastr: ToastrService
  ) {}

  setUserSettings(data: any) {
    this.userSettingsSource.next(data);
  }

  setUserToken(token: string) {
    return this.localStorage.setItem(this.tokenName, token);
  }
  get planExpiryAlert() {
    return this.showAlert;
  }
  set planExpiryAlert(plan: any) {
    if (typeof plan === 'boolean') {
      this.alertClosed = true;
      this.showAlert = false;
    } else if (plan && plan.is_trial && !this.alertClosed) {
      const dateDiff = moment(plan.trial_end).diff(moment(), 'd');

      this.showAlert =
        dateDiff <= 3
          ? `Your ${plan.service_plan?.name} (${plan.duration}) ${
              plan.trial_period
            } days free trial plan expires  ${
              dateDiff === 1
                ? 'tomorrow'
                : dateDiff <= 0
                ? 'today'
                : 'in ' + dateDiff + ' days'
            }!`
          : false;
    } else {
      this.alertClosed = true;
      this.showAlert = false;
    }
  }

  getUserByToken() {
    return this.http.get('me/users').pipe(
      map((resp: any) => {
        let user: any;
        if (resp.data) {
          const rawData = resp.data;
          if (rawData && rawData.id) {
            user = rawData;
            user.isWebUser =
              !rawData.purchased_device || rawData.purchased_device === 'web';
          }
          this.setUserSettings(user);
        }

        return user;
      })
    );
  }
  get getPartnerZipcodes() {
    return this.userSettingsSource.value.assigned_zipcodes.map((zip) => {
      return {
        country_code: zip.country_code,
        state_code: zip.state_code,
        state_name: zip.state_name,
        postal_code: zip.postal_code,
        place_name: zip.place_name,
      };
    });
  }
  partnerUniqueVal() {
    const partnerZip = this.userSettingsSource.value.assigned_zipcodes.map(
      (zip) => {
        return {
          country_code: zip.country_code,
          state_code: zip.state_code,
          state_name: zip.state_name,
          postal_code: zip.postal_code,
          place_name: zip.place_name,
        };
      }
    );
    const uniqueZipodes = {
      countries: [
        ...new Map(
          partnerZip.map((item) => [item['country_code'], item])
        ).values(),
      ],
      states: [
        ...new Map(
          partnerZip.map((item) => [item['state_name'], item])
        ).values(),
      ],
      zipcodes: partnerZip,
    };
    console.log(
      '🚀 ~ file: app-settings.service.ts:115 ~ AppSettingsService ~ partnerUniqueVal ~ uniqueCountryCodes:',
      uniqueZipodes
    );

    return uniqueZipodes;
  }

  /**
   * isAdmin
   */
  public isAdmin(): boolean {
    let flag = false;
    if (
      this.userSettingsSource.value &&
      this.userSettingsSource.value.role_id
    ) {
      if ([1, 2].indexOf(this.userSettingsSource.value.role_id) !== -1) {
        flag = true;
      }
    }
    return flag;
  }

  /**
   * isAdmin
   */
  public getLoggedinUserId(): boolean {
    let id = null;
    if (
      this.userSettingsSource.value &&
      this.userSettingsSource.value.role_id
    ) {
      id = this.userSettingsSource.value.id;
    }
    return id;
  }

  /**
   * getMenuItemsByRole
   */
  public getMenuItemsByRole() {
    let menu = [];
    let call_centre_id;
    if (this.userSettingsSource.value.call_centre) {
      call_centre_id = this.userSettingsSource.value.call_centre.id;
    }
    const menuItems = {
      admin: menuRoutes.filter((menuItem) => menuItem),
      saftey: [
        {
          path: '/request',
          title: 'Request',
          type: 'link',
          icontype: 'request_page',
        },
        {
          path: `/call-centre/view/${call_centre_id}`,
          title: 'Call Center',
          type: 'link',
          icontype: 'support_agent',
        },
      ],
      civillian: [
        {
          path: 'profile',
          title: 'My Profile',
          title_so: 'Mi perfil',
          type: 'link',
          icontype: 'person',
        },
        {
          path: 'request',
          title: 'Request',
          title_so: 'Pedido',
          type: 'link',
          icontype: 'request_page',
        },
        {
          path: `analytics/${this.userSettingsSource.value.id}`,
          title: 'Analytics',
          title_so: 'Analítica',
          type: 'link',
          icontype: 'trending_up',
        },
        {
          path: 'recordings',
          title: 'Recordings',
          title_so: 'Grabaciones',
          type: 'link',
          icontype: 'videocam',
        },
      ],
      lawyer: [
        {
          path: 'profile',
          title: 'My Profile',
          type: 'link',
          icontype: 'person',
        },
        {
          path: 'request',
          title: 'Request',
          type: 'link',
          icontype: 'request_page',
        },
        {
          path: 'recordings',
          title: 'Recordings',
          type: 'link',
          icontype: 'videocam',
        },
      ],
      staff: [
        {
          path: 'profile',
          title: 'My Profile',
          type: 'link',
          icontype: 'person',
        },
        {
          path: 'request',
          title: 'Request',
          type: 'link',
          icontype: 'request_page',
        },
        // {
        //   path: "recordings",
        //   title: "Recordings",
        //   type: "link",
        //   icontype: "videocam",
        // },
      ],
      distributor: [
        {
          path: '/partner/profile',
          title: 'My Profile',
          type: 'link',
          icontype: 'person',
        },
        // {
        //   path: 'promo-codes',
        //   title: 'Promo Codes',
        //   type: 'link',
        //   icontype: 'subscriptions',
        // },
        {
          path: 'articles',
          title: 'Articles',
          type: 'link',
          icontype: 'article',
        },
        {
          path: 'safety-tips',
          title: 'Safety Tips',
          type: 'link',
          icontype: 'tips_and_updates',
        },
        {
          path: 'banners',
          title: 'Banners',
          type: 'link',
          icontype: 'ad_units',
        },
        {
          path: 'videos',
          title: 'Videos',
          type: 'link',
          icontype: 'video_library',
        },
      ],
    };
    console.log('user', this.userSettingsSource.value);
    menuRoutes.filter((menuItem) => menuItem);
    if (this.userSettingsSource.value) {
      switch (
        this.userSettingsSource.value.role ||
        this.userSettingsSource.value.role_id
      ) {
        case 1:
          menu = menuItems.admin;
          break;
        case 3:
          menu = menuItems.civillian;
          const service_menu = {
            path: 'civilian-plans',
            title: 'Service Plans',
            title_so: 'Planes de servicio',
            type: 'link',
            icontype: 'manage_accounts',
          };
          if (this.userSettingsSource.value.isWebUser) {
            menuItems.civillian.push(service_menu);
          }

          break;
        case 4:
          menu = menuItems.lawyer;
          break;
        case 'distributor':
          menu = menuItems.distributor;
          break;
        case 5:
          menu = menuItems.saftey;
          break;
        case 6:
          menu = menuItems.staff;
          break;
        default:
          break;
      }
    }

    return menu;
  }

  /**
   * showSuccessMessage
   */
  public showSuccessMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      duration: 5000,
      verticalPosition: 'top',
      // horizontalPosition: "right",
      panelClass: 'success-dialog',
    };

    options = { ...defaultOptions, ...options };

    this.snackbar.open(message, 'OK', options);
  }

  /**
   * showErrorMessage
   */
  public showErrorMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'error-dialog',
    };

    options = { ...defaultOptions, ...options };

    this.snackbar.open(message, 'OK', options);
  }

  /**
   * showErrorMessage
   */
  public showWarningMessage(message = '', title = '', options: any = {}) {
    const defaultOptions = {
      timeout: 4000,
    };

    options = { ...defaultOptions, ...options };

    this.snackbar.open(message, 'OK', {
      duration: options.timeout,
    });
  }

  public sessionExpire() {
    return new Promise((resolve) => {
      const observables = [this.localStorage.removeItem(this.tokenName)];
      forkJoin(observables).subscribe(
        (response: any) => {
          this.httpCancelService.cancelPendingRequests();
          return resolve(true);
        },
        (error) => {
          return resolve(false);
        }
      );
    });
  }

  public queryStringFormat(queryParams: any) {
    const reqParams: any = {
      offset:
        queryParams.pageNumber > 0
          ? queryParams.pageNumber * queryParams.pageSize
          : 0,
      limit: queryParams.pageSize,
      sortField: 'id',
      sortOrder: 'DESC',
    };

    if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
      reqParams.filter = queryParams.filter;
    }

    if (queryParams.queryString) {
      reqParams.searchString = queryParams.queryString;
    }

    if (queryParams.sortField && queryParams.sortOrder) {
      reqParams.sortOrder = queryParams.sortOrder;
      reqParams.sortField = queryParams.sortField;
    }

    const queryString = Object.keys(reqParams)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(reqParams[k]);
      })
      .join('&');

    return queryString;
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    hideOkButton: boolean = false
  ): Promise<boolean> {
    btnOkText = btnOkText ? btnOkText : 'OK';
    btnCancelText = btnCancelText ? btnCancelText : 'Cancel';
    if (hideOkButton) {
      btnOkText = '';
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.maxWidth = '100%';
    dialogConfig.data = {
      title: title,
      message: message,
      btnOkText: btnOkText,
      btnCancelText: btnCancelText,
      hideOkButton: hideOkButton,
    };
    return new Promise((resolve, reject) => {
      const modalRef = this.matDialog.open(
        ConfirmDialogComponent,
        dialogConfig
      );
      modalRef.afterClosed().subscribe((result) => {
        if (!result) {
          resolve(false); // pass values
        } else {
          resolve(true); // pass values
        }
      });
    });
  }

  /**
   * exportCSV
   */
  public exportCSV(rows, fileName) {
    const options = {
      fieldSeparator: ',',
      filename: fileName,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: fileName,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(rows);
  }

  /**
   * getSatus
   */
  public getSatus() {
    return this.http
      .get(
        'request_status?filter=' +
          JSON.stringify({ where: { is_active: true } })
      )
      .pipe(
        map((resp: any) => {
          if (resp && Array.isArray(resp.data)) {
            this.statusList = [];
            for (const item of resp.data) {
              const key = item.name;
              if (item.name) {
                switch (key) {
                  case 'created':
                    item.name = 'Created';
                    break;
                  case 'accepted':
                    item.name = 'Accepted';
                    break;
                  case 'support':
                    item.name = 'Support';
                    break;
                  case 'cancelled_by_civilian':
                    item.name = 'Cancelled by civilian';
                    break;
                  case 'completed':
                    item.name = 'Completed';
                    break;
                  default:
                    break;
                }
              }

              this.statusList.push(item);
            }
          }
          return this.statusList;
        })
      );

    // let status = ['Accepted', 'Cancel']

    // return status;
  }

  /**
   * getPriorities
   */
  public getPriorities() {
    if (this.prioritiesList.length) {
      return of(this.prioritiesList);
    }

    return this.http.get('priorities').pipe(
      map((resp: any) => {
        if (resp && Array.isArray(resp.data)) {
          this.prioritiesList = [];
          for (const item of resp.data) {
            // if (item.is_active) {
            this.prioritiesList.push(item);
            // }
          }
        }
        return this.prioritiesList;
      })
    );
  }
  /**
   * getRedeemedCoupon
   */
  getRedeemedCoupon(filter) {
    if (this.couponsRedeemed.length) {
      return of(this.couponsRedeemed);
    }

    return this.http
      .get(`promocode_redeems?filter=${JSON.stringify(filter)}`)
      .pipe(
        map((resp: any) => {
          if (resp && Array.isArray(resp.data.rows)) {
            this.couponsRedeemed = [];
            for (const item of resp.data.rows) {
              // if (item.is_active) {
              this.couponsRedeemed.push(item);
              // }
            }
          }
          return this.couponsRedeemed;
        })
      );
  }
  /**
   * getSpecialities
   */
  public getSpecialities() {
    if (this.specialitiesList.length) {
      return of(this.specialitiesList);
    }

    return this.http.get('specialities').pipe(
      map((resp: any) => {
        if (resp && Array.isArray(resp.data)) {
          this.specialitiesList = [];
          for (const item of resp.data) {
            // if (item.is_active) {
            this.specialitiesList.push(item);
            // }
          }
        }
        return this.specialitiesList;
      })
    );
  }
}

export interface UserSettingsInterface {
  id: number;
  name: string;
  email: string;
  isVerified: boolean;
  isActive: boolean;
  role_id: number;
  role?: string;
}
