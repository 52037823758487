// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  wesiteURL: 'http://localhost:4000/#/',
  baseURL: 'https://aware360-api.tecnovaters.com/api/v1/',
  mediaUrl: 'https://aware360.s3.amazonaws.com/',
  tinyeditorMCE : 'h4tingd0j2bwcgxqx47m42a9z0bn1uc41fjys6gq3pbt74qr'
};
