import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { takeUntil, timeout, map, catchError, switchMap } from 'rxjs/operators';
import { AppSettingsService } from '../core/services/app-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { parseResponse } from '../core/helpers';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private baseURL = environment.baseURL;

  private jwtToken;
  private openURLs: Array<string> = ['/users/login', '/forgot_password'];
  private defaultTimeout = 60;

  constructor(
    private localStorage: LocalStorage,
    private appSettingsService: AppSettingsService,
    private spinner: NgxSpinnerService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.openURLs.indexOf(req.url) > -1) {
      this.jwtToken = undefined;
    }

    let skipLoader = false;
    if (!req.headers.has('skipLoader')) {
      this.spinner.show();
    } else {
      skipLoader = true;
      req = req.clone({
        headers: req.headers.delete('skipLoader'),
      });
    }
    if (this.jwtToken) {
      return this.prepareUrlAndHeaders(req, next, skipLoader);
    }

    return from(
      this.localStorage.getItem(this.appSettingsService.tokenName)
    ).pipe(
      switchMap((jwtToken) => {
        this.jwtToken = jwtToken;
        return this.prepareUrlAndHeaders(req, next, skipLoader);
      })
    );
  }

  private prepareUrlAndHeaders(
    req: HttpRequest<any>,
    next: HttpHandler,
    skipLoader: boolean = false
  ): Observable<HttpEvent<any>> {
    console.log('wdfhttp', req.url);
    req = req.clone({
      url:
        req.url.indexOf('http://') === 0 ||
        req.url.indexOf('https://') === 0 ||
        req.url.includes('/assets/i18n/')
          ? req.url
          : this.baseURL + req.url,
      setHeaders: {
        Authorization: `Bearer ${this.jwtToken}`,
      },
    });

    // Make request
    return next.handle(req).pipe(
      timeout(1000 * this.defaultTimeout),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (!skipLoader) {
            // setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            // }, 1000 * 1);
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.appSettingsService.sessionExpire().then((flag) => {
            if (flag) {
              window.location.href = '/login';
            }
          });
        }

        if (!skipLoader) {
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 1000 * 1);
        }

        return throwError(parseResponse(error.error));
      })
    );
  }
}
