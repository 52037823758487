import { RouteInfo } from 'src/app/modules/shared/sidebar/sidebar.component';

export let menuRoutes: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/civilian',
    title: 'Civilian',
    type: 'link',
    icontype: 'local_police',
  },
  // {
  //   path: "/lawyers",
  //   title: "Partners",
  //   type: "link",
  //   icontype: "gavel",
  // },
  {
    path: '/request',
    title: 'Request',
    type: 'link',
    icontype: 'request_page',
  },
  {
    path: '/broadcast',
    title: 'Broadcast',
    type: 'link',
    icontype: 'podcasts',
  },

  {
    path: '/service-plans',
    title: 'Service Plans',
    type: 'link',
    icontype: 'card_membership',
  },
  {
    path: '/recordings',
    title: 'Recordings',
    type: 'link',
    icontype: 'videocam',
  },

  {
    path: '/tutorials',
    title: 'Tutorials',
    type: 'link',
    icontype: 'app_settings_alt',
  },
  {
    path: '/cms',
    title: 'CMS',
    type: 'link',
    icontype: 'pages',
  },
  {
    path: '/configuration',
    title: 'Configuration',
    type: 'link',
    icontype: 'settings',
  },
  {
    path: '/static-qrcode',
    title: 'QR Code',
    type: 'link',
    icontype: 'qr_code',
  },
  {
    path: '/manage-promo',
    title: 'Manage Promo Code',
    type: 'sub',
    icontype: 'redeem',
    collapse: 'manage-promo',
    children: [
      {
        path: 'organization',
        title: 'Partners',
        icontype: 'handshake',
        ab: 'P',
      },
      {
        path: 'influencers',
        title: 'Influencers',
        icontype: 'psychology',
        ab: 'I',
      },
      {
        path: 'promo-codes',
        title: 'Promocodes',
        icontype: 'redeem',
        ab: 'P',
      },
      // { path: 'referrals', title: 'Referrals', ab: 'R' },
    ],
  },
  {
    path: '/subscriptions',
    title: 'Subscriptions',
    type: 'sub',
    icontype: 'subscriptions',
    collapse: 'subscriptions',
    children: [
      { path: 'invoice', title: 'Invoice', ab: 'I', icontype: 'receipt' },
      {
        path: 'reports',
        title: 'Reports',
        ab: 'R',
        icontype: 'receipt_long',
      },
    ],
  },

  // {
  //     path: '/support',
  //     title: 'Support',
  //     type: 'link',
  //     icontype: 'support_agent'
  // },
  // {
  //     path: '/call-centre',
  //     title: 'Call Centres',
  //     type: 'link',
  //     icontype: 'support_agent'
  // },
  // {
  //     path: '/call-centre/archived',
  //     title: 'Archived Call Centres',
  //     type: 'link',
  //     icontype: 'support_agent'
  // },
  {
    path: '/call-centre',
    title: 'Call Centers',
    type: 'sub',
    icontype: 'support_agent',
    collapse: 'call-centre',
    children: [
      {
        path: 'call-centres',
        title: 'Active Centers',
        ab: 'AC',
        icontype: 'domain',
      },
      {
        path: 'archived',
        title: 'Inactive Centers',
        ab: 'AC',
        icontype: 'domain_disabled',
      },
    ],
  },
  {
    path: '/manage_content',
    title: 'Manage Content',
    type: 'sub',
    icontype: 'assistant',
    collapse: 'zipcode',
    children: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        ab: 'D',
        icontype: 'dashboard',
      },
      {
        path: 'articles',
        title: 'Articles',
        ab: 'A',
        icontype: 'article',
      },
      {
        path: 'safety-tips',
        title: 'Safety Tips',
        ab: 'ST',
        icontype: 'tips_and_updates',
      },
      {
        path: 'banners',
        title: 'Banners',
        ab: 'B',
        icontype: 'ad_units',
      },
      {
        path: 'videos',
        title: 'Videos',
        ab: 'V',
        icontype: 'video_library',
      },
      {
        path: 'zipcode',
        title: 'Zipcode',
        ab: 'ZC',
        icontype: 'pin_drop',
      },

      // {
      //   path: 'emer_contact',
      //   title: 'Emergency Contact',
      //   ab: 'EC',
      //   icontype: 'perm_phone_msg',
      // },
    ],
  },
];
