import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public title = 'Please confirm';
  public message = 'Are you sure you want to Delete ?';
  public btnCancelText = 'Cancel';
  public btnOkText = 'Delete';
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title || 'Please confirm';
      this.message = this.data.message || 'Are you sure you want to Delete ?';
      this.btnCancelText = this.data.btnCancelText || 'Cancel';
      this.btnOkText = this.data.btnOkText || 'Delete';
    }
  }

  close(data) {
    this.dialogRef.close(data);
  }
}
